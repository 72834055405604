function ConcertCard({ concert }) {
	const dayOfWeek = concert.date.toLocaleDateString("fr-CA", {
		weekday: "long",
	});

	const month = concert.date.toLocaleDateString("fr-CA", {
		month: "long",
	});

	return (
		<section className="bg-white grid grid-cols-3 border-2 rounded-lg shadow-xl p-4 hover:scale-105 transition-all duration-300 items-center">
			<img
				src={concert.img}
				alt={concert.artist}
				className="object-cover rounded-lg h-32 w-32 lg:h-36 lg:w-36"
			/>

			<div className="my-auto font-bold text-left ml-2">
				<p className="2xl:text-xl">{concert.artist}</p>
				<p className="text-sm 2xl:text-md">{concert.secondaryArtist}</p>
			</div>

			<div className="my-auto text-right mr-4 font-bold">
				<h3 className="font-semibold col-span-3">
					{dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
				</h3>
				<p className="text-5xl">{concert.date.getDate()}</p>
				<p className="text-3xl">
					{month.charAt(0).toUpperCase() + month.slice(1)}
				</p>
			</div>
		</section>
	);
}

export default ConcertCard;
