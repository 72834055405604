import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import NavItem from "../components/NavItem";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

function Header() {
	const navigateHook = useNavigate();

	const items = [
		{
			name: "Accueil",
			link: "/#",
		},
		{
			name: "À propos",
			link: "/about",
			subCategories: [
				{
					name: "À propos",
					link: "",
				},
				{
					name: "Directeur artistique",
					link: "/directeur-artistique",
				},
			],
		},
		{
			name: "Notre orgue",
			link: "/orgue",
			subCategories: [
				{
					name: "Composition sonore",
					link: "",
				},
				{
					name: "100e de l'orgue",
					link: "/100e",
				},
				{
					name: "Photos",
					link: "/photos",
				},
			],
		},
		{
			name: "Éditions précédentes",
			link: "/editions-precedentes",
			subCategories: [
				{
					name: "Affiches",
					link: "",
				},

				{
					name: "Invités",
					link: "/invites",
				},
			],
		},
		{
			name: "Partenaires",
			link: "/partenaires",
		},
		/*{
			name: "Don",
			externalLink:
				"https://www.paypal.com/donate/?hosted_button_id=H8C4LTE",
		},*/
		{
			name: "Contact",
			link: "/contact",
		},
	];

	const [open, setOpen] = useState(false);

	const navigate = (link) => {
		navigateHook(link);
		setOpen(false);
	};

	return (
		<div className="mb-5">
			<div>
				<div className="bg-black h-4 w-full"></div>
			</div>
			<nav className="pl-4 pr-4 lg:w-auto lg:pl-32 lg:pr-32 text-black bg-white shadow-lg font-semibold w-full p-2 lg:bg-white lg:flex">
				<div className="flex justify-between lg:inline-block lg:px-2 lg:mr-2 lg:border-r lg:border-gray-800">
					<img
						src={logo}
						alt="logo"
						className="h-16 object-contain lg:-mt-1 lg:inline lg:mx-auto cursor-pointer"
						onClick={() => navigate("/")}
					/>

					<FontAwesomeIcon
						icon={faBars}
						className="ml-auto my-auto lg:hidden"
						size="2x"
						onClick={() => setOpen(!open)}
					/>
				</div>

				<div
					className={classNames(
						"bg-white w-full overflow-hidden lg:hidden",
						{
							"navOpen visible": open,
							hidden: !open,
						}
					)}
				>
					<span className="px-2 cursor-pointer text-sm transition-all duration-200 ml-auto flex flex-col">
						{items.map((item, index) => (
							<NavItem
								item={item}
								key={index}
								navigate={navigate}
							/>
						))}
					</span>
				</div>
				<span className="cursor-pointer text-sm transition-all duration-200 ml-auto mt-1 hidden lg:inline-block">
					{items.map((item, index) => (
						<NavItem item={item} key={index} navigate={navigate} />
					))}

					<FontAwesomeIcon
						icon={faFacebook}
						className="text-3xl hover:text-blue-500 transition-all cursor-pointer inline-block ml-4 mr-3 translate-y-1"
						onClick={() =>
							window.open(
								"https://www.facebook.com/festivalorguedesaintemarie",
								"_blank"
							)
						}
					/>
				</span>
			</nav>
		</div>
	);
}

export default Header;
