import PageTitle from "../../components/PageTitle";

import organJson from "../../assets/json/organ.json";
import organPipes from "../../assets/organ/20.jpeg";

function Composition() {
	const organ = organJson;

	return (
		<div>
			<PageTitle title="Composition sonore de l'orgue" />

			<div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-10 lg:gap-0">
				<div>
					<div className="mb-10">
						<p>
							La console électro-pneumatique comprend 3 claviers
							manuels de 61 notes et un pédalier de 32 notes.
						</p>
						<div className="m-2">
							<li>Casavant Frères 1916, restauration 1990</li>
							<li>Électrification en 1964</li>
							<li>Ajout d’une résultante 32′ en 2017</li>
						</div>
					</div>

					<div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
						{organ.map((item, index) => (
							<div key={index} className="border-2 w-full p-3">
								<h2 className="font-semibold mb-3 text-center">
									{item.name}
								</h2>
								<ul className="text-sm">
									{item.stops.map((stop, index) => (
										<li
											key={index}
											className="flex justify-between"
										>
											<span>{stop.name}</span>
											<span>{stop.size}</span>
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</div>
				<img
					src={organPipes}
					alt="Orgue"
					className="object-cover w-3/4 mx-auto"
				/>
			</div>
			<h2 className="font-semibold mt-10 mb-3">
				Caractéristiques supplémentaires
			</h2>

			<h4>Accouplements:</h4>
			<div className="m-2 mt-0 mb-3">
				<li>GO/PED 8,4; POS/PED 8,4; REC/PED 8,4</li>
				<li>REC/GO 16,8,4</li>
				<li>POS/GO 8,4</li>
				<li>REC/POS 8,4</li>
			</div>

			<h4>Combinaisons:</h4>
			<li className="m-2 mb-0 mt-0">Clavier</li>
			<div className="list-[circle] m-6 mt-0 mb-3">
				<li>REC/PED; REC 4</li>
				<li>GO/PED, REC/GO, POS/GO; GO 4</li>
				<li>POS/PED; PED 4; POS 4</li>
			</div>

			<li className="m-2 mb-0 mt-0">Pédalier (4 généraux)</li>
			<li className="list-[circle] m-6 mt-0">
				REC/PED, GO/PED, POS/PED; Grand Jeu
			</li>
		</div>
	);
}

export default Composition;
