import guestsJson from "../../assets/json/guests.json";
import PageTitle from "../../components/PageTitle";

function Guests() {
	const guests = guestsJson;

	return (
		<div>
			<PageTitle title="Invités" />
			<div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-3 lg:gap-8 lg:m-12 mb-10">
				{guests.map((guest, index) => (
					<div
						key={index}
						className="w-full border-2 rounded-lg shadow-xl p-4 hover:scale-105 transition-all duration-300"
					>
						<h2 className="text-lg font-semibold">{guest.year}</h2>

						{guest.artists.map((artist, index) => (
							<li key={index}>{artist}</li>
						))}
					</div>
				))}
			</div>
		</div>
	);
}

export default Guests;
