import ReactImageGallery from "react-image-gallery";
import affiche2014 from "../../assets/affiches/2014.jpg";
import affiche2015 from "../../assets/affiches/2015.jpeg";
import affiche2016 from "../../assets/affiches/2016.jpeg";
import affiche2017 from "../../assets/affiches/2017.jpeg";
import affiche2018 from "../../assets/affiches/2018.jpeg";
import affiche2019 from "../../assets/affiches/2019.jpeg";
import affiche2022 from "../../assets/affiches/2022.jpeg";
import affiche2023 from "../../assets/affiches/2023.jpg";

import PageTitle from "../../components/PageTitle";

function Posters() {
	const items = [
		{
			original: affiche2014,
			thumbnail: affiche2014,
		},
		{
			original: affiche2015,
			thumbnail: affiche2015,
		},
		{
			original: affiche2016,
			thumbnail: affiche2016,
		},
		{
			original: affiche2017,
			thumbnail: affiche2017,
		},
		{
			original: affiche2018,
			thumbnail: affiche2018,
		},
		{
			original: affiche2019,
			thumbnail: affiche2019,
		},
		{
			original: affiche2022,
			thumbnail: affiche2022,
		},
		{
			original: affiche2023,
			thumbnail: affiche2023,
		},
	];

	return (
		<div>
			<PageTitle title="Affiches" />

			<div className="mx-auto w-full lg:w-[40%] 2xl:w-[34%]">
				<ReactImageGallery
					items={items}
					startIndex={items.length - 1}
					infinite={false}
					autoPlay={false}
					thumbnailPosition="left"
					slideInterval={4000}
				/>
			</div>
		</div>
	);
}

export default Posters;
