import assembleenationale from "../../assets/partners/assembleenationale.jpg";
import desjardins from "../../assets/partners/desjardins.png";
import edgar from "../../assets/partners/edgar.jpg";
import gvachonnotaire from "../../assets/partners/gvachonnotaire.jpg";
import icb from "../../assets/partners/icb.jpg";
import imago from "../../assets/partners/imago.gif";
import planiassure from "../../assets/partners/planiassure.jpeg";
import vergertipaul from "../../assets/partners/vergertipaul.jpg";
import ville_stemarie from "../../assets/partners/ville_stemarie.png";
import vsm from "../../assets/partners/vsm.jpeg";

import PageTitle from "../../components/PageTitle";

function Partners() {
	const partners = [
		{
			logo: desjardins,
			name: "Desjardins",
			link: "https://www.desjardins.com/",
		},
		{
			logo: ville_stemarie,
			name: "Ville de Sainte-Marie",
			link: "https://www.sainte-marie.ca/",
		},
		{
			logo: imago,
			name: "Imago",
			link: "https://goimago.com/",
		},
		{
			logo: gvachonnotaire,
			name: "Gaston Vachon Notaire inc.",
			link: "https://www.gastonvachonnotaire.net",
		},
		{
			logo: vergertipaul,
			name: "Le Verger à Ti-paul",
			link: "https://levergeratipaul.com/",
		},
		{
			logo: assembleenationale,
			name: "Assemblée nationale du Québec",
			link: "https://www.assnat.qc.ca/fr/index.html?appelant=MC",
		},
		{
			logo: icb,
			name: "Imprimerie Commercial de Beauce",
			link: "http://www.imprimeriecommercialedebeauce.com",
		},
		{
			logo: planiassure,
			name: "Plani-Assure",
			link: "https://plani-assure.ca",
		},
		{
			logo: edgar,
			name: "Edgar Mercier & fils inc.",
			link: "https://www.edgarmercier.com",
		},
		{
			logo: vsm,
			name: "VSM",
			link: "https://vsmauto.com",
		},
	];

	return (
		<div>
			<PageTitle title="Nos partenaires" />
			<div className="flex flex-wrap gap-16 justify-center items-center pt-10 pb-10">
				{partners.map((partner, index) => (
					<img
						key={index}
						className="h-28 object-contain cursor-pointer"
						src={partner.logo}
						alt={partner.name}
						onClick={() => window.open(partner.link, "_blank")}
					/>
				))}
			</div>
		</div>
	);
}

export default Partners;
