import PageTitle from "../../components/PageTitle";
import organ from "../../assets/organ/18.jpeg";

function Hundredth() {
	return (
		<div>
			<PageTitle title="100e de l'orgue" />

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
				<div className="lg:mb-10">
					<h2 className="text-xl mb-3 font-semibold">
						L’orgue Casavant de Sainte-Marie de Beauce est
						centenaire!
					</h2>
					<p className="mb-3">
						C’est en effet le 17 décembre 1916 qu’a eu lieu
						l’inauguration de l’orgue installé par le facteur
						québécois Casavant Frères au coût de 6000 $. Cet orgue
						de 34 jeux répartis sur trois claviers et un pédalier
						est l’opus 674 de ce facteur réputé. Auparavant, la
						Fabrique de Sainte-Marie s’était dotée d’un orgue du
						facteur québécois Napoléon Déry en 1891 au coût de 1900
						$. C’est donc en gardant quelques éléments de cet orgue
						Déry que Casavant fit subir une restauration complète à
						l’instrument, en ajoutant une soufflerie électrique et
						après coup, addition des jeux de harpe et de carillon.
						Considéré comme un instrument pratiquement neuf, le
						grand orgue de Sainte-Marie a été inauguré par le
						célèbre Henri Gagnon, organiste de Notre-Dame de Québec,
						avec le concours de la chorale de Léon Dessane (qui fut
						lui-même organiste titulaire des grandes orgues de
						Saint-Roch pendant 33 ans). Cinq ans plus tard, en 1921,
						l’orgue était honoré de sa visite par le virtuose
						français Joseph Bonnet.
					</p>
					<p className="mb-3">
						Avant 1891, deux orgues ont servi le culte qui, dit-on,
						n’étaient pas de grande qualité; celui de
						Charles-Auguste Fay installé en 1842 et celui d’un
						certain Beaudoin qui a servi jusqu’à l’achat du Déry en
						1891.
					</p>
					<p>
						Actuellement, s’il fallait reconstruire l’orgue de
						Sainte-Marie, il en coûterait 1,100,000.00 $! Au cours
						des années, il a subi quelques transformations notamment
						avec l’électrification en 1964, une transformation en
						1990 et l’ajout d’une résultante 32′ en 2017. Il est
						considéré comme l’un des joyaux de la Beauce.
					</p>

					<h2 className="text-xl mb-3 mt-10 font-semibold">
						Liste des organistes titulaires de la paroisse de
						Sainte-Marie de Beauce
					</h2>
					<p>1843 ➜ Georges-H. Louis</p>
					<p>
						1859 ➜ Mlle Virginie Noël et Théodore Labbé,
						Charles-Alphonse Riverin (pas confirmés)
					</p>
					<p>1889 ➜ Henri Carrier</p>
					<p>1927 ➜ Hector Tanguay</p>
					<p>1942 ➜ Mlle Gilberte Savoie</p>
					<p>1946 ➜ Frère Félix</p>
					<p>1953 ➜ Paul-Émile Morency</p>
					<p>1991 ➜ Christian Bolduc et Michel Grenier</p>
					<p>1993 ➜ Dominique Gagnon</p>
				</div>

				<img
					src={organ}
					alt="organ"
					className="object-cover w-3/4 mx-auto mb-10 lg:mb-0"
				/>
			</div>
		</div>
	);
}

export default Hundredth;
