import { Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

import About from "./pages/about/About";
import ArtDirector from "./pages/about/ArtDirector";

import Composition from "./pages/organ/Composition";
import Hundredth from "./pages/organ/Hundredth";
import Photos from "./pages/organ/Photos";

import Partners from "./pages/partners/Partners";
import Contact from "./pages/contact/Contact";

import Posters from "./pages/editions/Posters";
import Guests from "./pages/editions/Guests";

import MessengerBubble from "./components/MessengerBubble";

function App() {
	return (
		<div className="monterrat flex min-h-screen flex-col justify-between">
			<div>
				<Header />

				<div className="pl-3 pr-3 lg:pl-36 lg:pr-36">
					<Routes>
						<Route index element={<Home />} />
						<Route
							path="programme"
							element={<Home displayProgramModal={true} />}
						/>

						<Route path="about">
							<Route index element={<About />} />
							<Route
								path="directeur-artistique"
								element={<ArtDirector />}
							/>
						</Route>

						<Route path="editions-precedentes">
							<Route index element={<Posters />} />
							<Route path="invites" element={<Guests />} />
						</Route>

						<Route path="orgue">
							<Route index element={<Composition />} />
							<Route path="100e" element={<Hundredth />} />
							<Route path="photos" element={<Photos />} />
						</Route>

						<Route path="partenaires" element={<Partners />} />
						<Route path="contact" element={<Contact />} />

						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</div>

			<MessengerBubble />
			<Footer />
		</div>
	);
}

export default App;
