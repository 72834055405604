import home from "../assets/home.jpg";

import choeurAdVitam from "../assets/artists/ad_vitam.jpg";
import aubertLavoie from "../assets/artists/aubert_lavoie.jpg";
import henryWebb from "../assets/artists/henry_webb.jpg";

import { useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import ConcertCard from "../components/home/ConcertCard";
import ProgramModal from "../components/modals/ProgramModal";

function Home({ displayProgramModal }) {
	const [modalVisible, setModalVisible] = useState(false);

	const concerts = [
		{
			date: new Date(2024, 5, 2),
			artist: "Choeur Ad Vitam",
			secondaryArtist: "et Dominique Gagnon",
			img: choeurAdVitam,
		},
		{
			date: new Date(2024, 5, 9),
			artist: "Henry Webb",
			secondaryArtist: "",
			img: henryWebb,
		},
		{
			date: new Date(2024, 5, 16),
			artist: "Aubert Lavoie",
			secondaryArtist: "",
			img: aubertLavoie,
		},
	];

	useEffect(() => {
		const now = new Date();
		const start = Date.UTC(2024, 5, 2, 18, 0);
		const end = Date.UTC(2024, 5, 2, 21, 0);

		if ((now > start && now < end) || displayProgramModal)
			setModalVisible(true);
	}, [displayProgramModal]);

	return (
		<div className="text-center">
			<img
				src={home}
				alt="organ"
				className="object-contain w-full mt-8 shadow-xl rounded"
			/>

			<PageTitle title="22e édition | 2024" className="m-8" />
			<h2 className="text-xl mb-10 font-semibold">
				Tous nos concerts sont présentés gratuitement à{" "}
				<span className="font-bold">15h00</span> en l'église de
				Sainte-Marie !
			</h2>

			<div className="grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-8 lg:m-12 mb-10">
				{concerts.map((concert, index) => (
					<ConcertCard concert={concert} key={index} />
				))}
			</div>

			<ProgramModal
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
			/>
		</div>
	);
}

export default Home;
