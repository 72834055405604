import dominique from "../../assets/artists/dominique_gagnon.jpeg";
import PageTitle from "../../components/PageTitle";

function ArtDirector() {
	return (
		<div>
			<PageTitle title="Directeur artistique" />

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-32 items-center">
				<div>
					<h2 className="text-xl mb-5 font-semibold">
						Dominique Gagnon
					</h2>
					<p className="mb-5">
						Dominique Gagnon est admis au Conservatoire de musique
						de Québec en 1989 sous la direction de Mme Noëlla
						Genest, où il obtient, en 1998, le premier prix avec «
						Grande distinction » à l’unanimité du jury, couronnant
						le niveau Supérieur II du Conservatoire de musique de
						Québec. Il est boursier pour la meilleure note obtenue
						aux examens terminaux du Supérieur I et Supérieur II,
						toutes catégories d’instruments confondues. Il peut se
						perfectionner, en 1997, auprès de Pierre Perdigon,
						titulaire de la classe d’orgue du Conservatoire de
						Grenoble, et en 1999, auprès de Kenneth Gilbert,
						professeur honoraire du Conservatoire de Paris et
						professeur émérite au Mozarteum de Salzbourg.
					</p>

					<p className="mb-5">
						Dominique Gagnon est double-lauréat du Concours d’orgue
						de Québec, 1998 et 2001, où il obtient à deux reprises
						le 2e prix avec « mention spéciale » ainsi que le prix
						de la meilleure interprétation de l’œuvre imposée lors
						de l’édition de 1998. En novembre 1999, il remporte le
						premier prix du Concours John Robb du RCCO à Montréal.
					</p>

					<p className="mb-5">
						Nommé organiste titulaire des grandes orgues de
						Sainte-Marie par voie de concours en 1993, Dominique
						occupe ce poste depuis. Il fonde le Festival d’orgue de
						Sainte-Marie en 2000, qui accueille des organistes qui
						brillent sur la scène nationale et internationale et qui
						contribuent à faire connaître et rayonner ce magnifique
						instrument.
					</p>

					<p className="mb-5">
						Dominique Gagnon se fait entendre dans de nombreux
						concerts en tant que soliste partout au Québec,
						notamment au Palais Montcalm et à l’Oratoire
						Saint-Joseph du Mont-Royal à Montréal. Il est très actif
						sur la scène musicale en tant que soliste, continuiste
						et accompagnateur pour de nombreux chœurs. Il participe
						à des enregistrements pour la société Radio-Canada dans
						le cadre de la série « Jeunes artistes » et il
						accompagne des chœurs et chanteurs, notamment pour
						l’album « Harmonia Voce » avec l’ensemble Polyphonia de
						Québec. Il est également membre de différents jurys pour
						des examens terminaux ainsi que pour des concours.
					</p>

					<p className="lg:mb-10">
						Dominique Gagnon vient tout juste d’obtenir le poste de
						professeur d’orgue au Conservatoire de musique du Québec
						à Québec. Il enseigne également l’orgue au Cégep de
						Sainte-Foy et est pianiste-accompagnateur au Campus
						Notre-Dame-de-Foy à Saint-Augustin.
					</p>
				</div>

				<img
					src={dominique}
					alt="Dominique Gagnon"
					className="object-cover w-3/4 mx-auto mb-10 lg:mb-0"
				/>
			</div>
		</div>
	);
}

export default ArtDirector;
