import PageTitle from "../../components/PageTitle";

import dominique from "../../assets/artists/dominique_gagnon.jpeg";
import esther from "../../assets/artists/esther_clement.png";

function About() {
	return (
		<div>
			<PageTitle title="À propos" />

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-32 items-center">
				<div>
					<p className="mb-5">
						Le{" "}
						<span className="font-semibold">Festival d’orgue</span>{" "}
						de Sainte-Marie est né en{" "}
						<span className="font-semibold">2001</span> de la
						passion de deux organistes professionnels pour cet
						instrument majestueux qu’est l’orgue à tuyaux. Dominique
						Gagnon, titulaire du grand orgue depuis 1993, ainsi
						qu’Esther Clément, organiste titulaire de l’église de
						Saint-Joseph-de-Beauce et de Vallée-Jonction, ont eu
						l’idée de créer ce festival afin de mettre en valeur les
						grandes orgues de Sainte-Marie. Cet orgue plus que
						centenaire de 35 jeux, qui a toujours été bien
						entretenu, a subi quelques restaurations et ajouts au
						fil du temps. Jouant déjà un rôle important dans la
						liturgie, les fondateurs souhaitaient également qu’il
						puisse résonner lors de grands événements afin que le
						public découvre l’immense répertoire de concert que les
						compositeurs ont dédié à cet instrument orchestral.
					</p>
					<p className="lg:mb-10">
						Après bon nombre d’années d’existence, le Festival jouit
						d’une excellente réputation, tant auprès du grand public
						que des artistes qui s’y produisent. Depuis sa création,
						le Festival a accueilli plusieurs dizaines d’organistes
						professionnels, plusieurs chœurs de la grande région de
						la Beauce et de Québec ainsi que des instrumentistes et
						chanteurs chevronnés. Le Festival présente chaque année{" "}
						<span className="font-semibold">trois concerts</span> au
						mois de juin, les dimanches après-midi à{" "}
						<span className="font-semibold">15 h</span>. Chaque
						concert est retransmis sur écran géant afin de pouvoir
						apprécier visuellement la performance de l’artiste en
						pleine action et l’entrée est libre. Plusieurs
						partenaires de la région permettent au Festival de
						poursuivre sa mission, soit de présenter des concerts de
						grande qualité, et ce,{" "}
						<span className="font-semibold">gratuitement</span>.
					</p>
				</div>

				<div className="flex lg:gap-10 mb-10 lg:mb-0">
					<img
						src={dominique}
						alt="Dominique Gagnon"
						className="w-1/2 object-cover"
					/>
					<img
						src={esther}
						alt="Esther Clément"
						className="w-1/2 object-cover"
					/>
				</div>
			</div>
		</div>
	);
}

export default About;
