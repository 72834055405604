import { useEffect } from "react";

function MessengerBubble() {
	useEffect(() => {
		window.fbAsyncInit = () => {
			window.FB.init({
				xfbml: true,
				version: "v8.0",
			});
		};

		(function (d, s, id) {
			let js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src =
				"https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js";
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
	}, []);

	return <div className="fb-customerchat" page_id={"213201622519630"} />;
}

export default MessengerBubble;
