import img01 from "../../assets/organ/1.jpeg";
import img02 from "../../assets/organ/2.jpeg";
import img03 from "../../assets/organ/3.jpeg";
import img04 from "../../assets/organ/4.jpeg";
import img05 from "../../assets/organ/5.jpeg";
import img06 from "../../assets/organ/6.jpeg";
import img07 from "../../assets/organ/7.jpeg";
import img08 from "../../assets/organ/8.jpeg";
import img09 from "../../assets/organ/9.jpeg";
import img10 from "../../assets/organ/10.jpeg";
import img11 from "../../assets/organ/11.jpeg";
import img12 from "../../assets/organ/12.jpeg";
import img13 from "../../assets/organ/13.jpeg";
import img14 from "../../assets/organ/14.jpeg";
import img15 from "../../assets/organ/15.jpeg";
import img16 from "../../assets/organ/16.jpeg";
import img17 from "../../assets/organ/17.jpeg";
import img18 from "../../assets/organ/18.jpeg";
import img19 from "../../assets/organ/19.jpeg";
import img20 from "../../assets/organ/20.jpeg";
import img21 from "../../assets/organ/21.jpeg";
import img22 from "../../assets/organ/22.jpeg";
import img23 from "../../assets/organ/23.jpeg";
import img24 from "../../assets/organ/24.jpeg";
import img25 from "../../assets/organ/25.jpeg";
import img26 from "../../assets/organ/26.jpeg";
import img27 from "../../assets/organ/27.jpeg";
import img28 from "../../assets/organ/28.jpeg";
import img29 from "../../assets/organ/29.jpeg";
import img30 from "../../assets/organ/30.jpeg";

import ReactImageGallery from "react-image-gallery";
import PageTitle from "../../components/PageTitle";

function Photos() {
	const items = [
		{
			thumbnail: img01,
			original: img01,
		},
		{
			thumbnail: img02,
			original: img02,
		},
		{
			thumbnail: img03,
			original: img03,
		},

		{
			thumbnail: img04,
			original: img04,
		},
		{
			thumbnail: img05,
			original: img05,
		},
		{
			thumbnail: img06,
			original: img06,
		},
		{
			thumbnail: img07,
			original: img07,
		},
		{
			thumbnail: img08,
			original: img08,
		},

		{
			thumbnail: img09,
			original: img09,
		},
		{
			thumbnail: img10,

			original: img10,
		},
		{
			thumbnail: img11,
			original: img11,
		},
		{
			thumbnail: img12,
			original: img12,
		},
		{
			thumbnail: img13,
			original: img13,
		},
		{
			thumbnail: img14,

			original: img14,
		},
		{
			thumbnail: img15,
			original: img15,
		},
		{
			thumbnail: img16,
			original: img16,
		},
		{
			thumbnail: img17,
			original: img17,
		},
		{
			thumbnail: img18,

			original: img18,
		},
		{
			thumbnail: img19,
			original: img19,
		},
		{
			thumbnail: img20,
			original: img20,
		},
		{
			thumbnail: img21,
			original: img21,
		},
		{
			thumbnail: img22,
			original: img22,
		},
		{
			thumbnail: img23,
			original: img23,
		},
		{
			thumbnail: img24,
			original: img24,
		},
		{
			thumbnail: img25,
			original: img25,
		},
		{
			thumbnail: img26,
			original: img26,
		},
		{
			thumbnail: img27,
			original: img27,
		},
		{
			thumbnail: img28,
			original: img28,
		},
		{
			thumbnail: img29,
			original: img29,
		},
		{
			thumbnail: img30,
			original: img30,
		},
	];

	return (
		<div>
			<PageTitle title="Photos" />
			<h2 className="text-center -mt-10 mb-10">
				Photographe: Ghislaine Crête
			</h2>

			<div className="mx-auto w-full lg:w-[40%]">
				<ReactImageGallery
					items={items}
					autoPlay={true}
					slideInterval={4000}
				/>
			</div>
		</div>
	);
}

export default Photos;
