import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

function NavItem({ item, navigate }) {
	const location = useLocation();

	return (
		<div
			className={classNames(
				"inline-block font-bold lg:font-medium relative group w-full lg:w-auto md:hover:bg-black md:hover:text-white transition-all duration-300",
				{
					"md:bg-black md:text-white": location.pathname.startsWith(
						item.link
					),
				}
			)}
		>
			<div
				className="mx-1 p-3 lg:p-4 lg:px-2 mt-2 sm:mt-0"
				onClick={() => {
					if (item.externalLink)
						return window.open(item.externalLink, "_blank");

					navigate(item.link);
				}}
			>
				{item.name}
				{item.subCategories && (
					<div className="hidden md:inline ml-3 lg:ml-1 text-xs lg:text-sm">
						<FontAwesomeIcon icon={faChevronDown} />
					</div>
				)}
			</div>

			{item.subCategories && (
				<ul className="lg:absolute w-full transition-all duration-300 lg:opacity-0 lg:invisible lg:group-hover:visible lg:group-hover:opacity-100 ml-7 sm:m-0">
					{item.subCategories.map((subCategory, index) => (
						<div
							onClick={() =>
								navigate(`${item.link}${subCategory.link}`)
							}
							to={`${item.link}${subCategory.link}`}
							key={index}
						>
							<li className="p-2 md:bg-white md:text-black md:hover:bg-black md:hover:text-white transition-all duration-300">
								{subCategory.name}
							</li>
						</div>
					))}
				</ul>
			)}
		</div>
	);
}

export default NavItem;
