import PageTitle from "../../components/PageTitle";

function Contact() {
	return (
		<div className="text-center">
			<PageTitle title="Contact" />

			<div className="mb-10">
				<h2 className="font-semibold text-lg">Organisateurs</h2>
				<p>Dominique Gagnon, directeur artistique</p>
				<p>
					Esther Clément, trésorière et responsable des communications
				</p>
				<p>Mireille Fortier, révision des textes</p>
				<p className="mt-2 underline">
					<a href="mailto:info@festivalorgue.org">
						info@festivalorgue.org
					</a>
				</p>
				<p>(418) 386-2969</p>
			</div>

			<div className="mb-10">
				<h2 className="font-semibold text-lg">Adresse postale</h2>
				<p>536 Boulevard Taschereau Sud,</p>
				<p>Sainte-Marie de Beauce</p>
				<p>Québec, G6E 1V4</p>
			</div>
			<div>
				<h2 className="font-semibold text-lg">
					Église de Sainte-Marie de Beauce
				</h2>
				<p>62, rue Notre-Dame Sud</p>
				<p>Sainte-Marie de Beauce</p>
				<p>Québec, G6E 4A2</p>
			</div>
		</div>
	);
}

export default Contact;
