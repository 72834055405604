import classNames from "classnames";

function PageTitle({ title, className }) {
	return (
		<h1
			className={classNames(
				"m-14 text-3xl lg:text-4xl font-bold text-center",
				{
					[className]: className,
				}
			)}
		>
			{title}
		</h1>
	);
}

export default PageTitle;
