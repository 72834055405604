import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";

function ProgramModal({ modalVisible, setModalVisible }) {
	const navigateHook = useNavigate();

	const title = {
		date: "16 Juin",
		artist: "Aubert Lavoie",
	};

	const programme = [
		{
			title: "Praeludium en sol mineur, BuxWV 148",
			author: "Dietrich Buxtehude (1637-1707)",
		},
		{
			title: "Hommage à Dietrich Buxtehude, Toccata et fugue",
			author: "Petr Eben (1929-2007)",
			items: [
				"I-Con enfasi, ma più Allergro che Buxtehude",
				"II-Ben ritmico",
				"III-Scherzando",
				"IV-Tempo I",
			],
		},
		{
			title: "Toccata no 1, 2e Livre",
			author: "Girolamo Frescobaldi (1583-1637)",
		},
		{
			title: "Hommage à Frescobaldi",
			author: "Jean Langlais (1907-1991)",
			items: [
				"I-Prélude au Kyrie",
				"VII-Thème et Variations",
				"V-Fantaisie",
			],
		},
		{
			title: "Pièces de Fantaisie, suite no 3, opus 54",
			author: "Louis Vierne (1870-1937)",
			items: ["Impromptu"],
		},
		{
			title: "Six Études chorales",
			author: "Thierry Escaich (né en 1965)",
			items: ["Étude no 4 « O Haupt voll Blut und Wunden »"],
		},
		{
			title: "Toccata et fugue en mi majeur, BWV 566",
			author: "Johann Sebastian Bach (1685-1750)",
		},
	];

	return (
		<Popup
			modal
			open={modalVisible}
			onClose={() => setModalVisible(false)}
			contentStyle={{
				width: window.innerWidth > 768 ? "50%" : "90%",
				height: window.innerHeight > 768 ? "75%" : "85%",
				borderRadius: "10px",
				paddingLeft: "20px",
				paddingRight: "20px",
			}}
			closeOnDocumentClick={window.innerWidth > 768 ? true : false}
		>
			{(close) => (
				<>
					<button
						className="absolute -right-3 -top-3 bg-white rounded-full w-8 h-8 flex justify-center items-center text-red-500 font-bold text-2xl border-2 border-black outline-none"
						onClick={close}
					>
						<span className="mb-[2px] ml-[1px]">&times;</span>
					</button>

					<div className="overflow-y-auto h-full">
						<div className="mb-2 pt-4">
							<h1 className="text-center font-bold text-2xl">
								Programme - {title.date}
							</h1>
							<h2 className="text-center font-semibold text-xl">
								{title.artist}
							</h2>
						</div>

						{programme.map((piece, index) => (
							<div
								className={classNames(
									"grid grid-cols-10 border-b-2 py-2",
									{
										"border-t-2": index === 0,
									}
								)}
								key={index}
							>
								<div className="col-span-6">
									<span className="font-semibold">
										{piece.title}
									</span>
									{piece.items && (
										<ul className="list-disc list-inside -space-y-1">
											{piece.items.map((item, index) => (
												<li key={index}>{item}</li>
											))}
										</ul>
									)}
								</div>
								<div className="col-span-4 text-right">
									{piece.author}
								</div>
							</div>
						))}

						<div className="p-3">
							<div className="flex justify-between mb-2">
								<button
									className="bg-orange-500 text-white font-bold py-2 rounded-full w-[48%]"
									onClick={() => {
										close();
										navigateHook("/partenaires");
									}}
								>
									Nos partenaires
								</button>

								<button className="bg-blue-500 text-white font-bold py-2 rounded-full w-[48%]">
									<a
										href="https://www.facebook.com/festivalorguedesaintemarie"
										target="_blank"
										rel="noreferrer"
									>
										Notre Facebook
									</a>
								</button>
							</div>
							<button
								className="bg-red-500 text-white font-bold py-2 rounded-full w-full"
								onClick={() =>
									window.open("/programme2024.pdf", "_blank")
								}
							>
								Programme complet
							</button>
						</div>
					</div>
				</>
			)}
		</Popup>
	);
}

export default ProgramModal;
